import {Box, StyledOcticon} from '@primer/react'
import {XIcon} from '@primer/octicons-react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {GitHubUser} from '../types'
import {API_HOSTNAME} from '../urls'
import {AutocompleteBase} from './AutocompleteBase'
import {UserAvatar} from './UserAvatar'

type CollaboratorSelectorProps = {
  users: GitHubUser[]
  setUsers: (users: GitHubUser[]) => void
  repo: string
  owner: string
}
export const CollaboratorSelector: React.FC<CollaboratorSelectorProps> = ({users, setUsers, repo, owner}) => {
  const url = `https://${API_HOSTNAME}/repos/${owner}/${repo}/assignees`
  const {data: allUsers} = useGitHubQuery<GitHubUser[]>(url)

  const allTokens = (allUsers || []).map(user => ({
    ...user,
    text: user.login,
    leadingVisual: userLeadingVisual(user)
  }))

  return (
    <AutocompleteBase
      tokens={users}
      setTokens={setUsers}
      allTokens={allTokens}
      onChange={search => console.log({search})}
      tokenComponent={LabelToken}
    />
  )
}

const LabelToken: React.FC<GitHubUser & {onRemove: () => void}> = user => {
  return (
    <Box sx={{borderColor: 'border.subtle', borderWidth: 1, borderStyle: 'solid', borderRadius: 6}}>
      <UserAvatar user={user} size={24} sx={{ml: 1, mr: 2}} />
      {user.login}
      <Box onClick={user.onRemove} sx={{display: 'inline', px: 1, cursor: 'pointer'}}>
        <StyledOcticon icon={XIcon} sx={{color: 'fg.default'}} />
      </Box>
    </Box>
  )
}

const userLeadingVisual = (user: GitHubUser) => {
  return () => <UserAvatar user={user} size={18} />
}
