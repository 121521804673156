import {Box, Label, StyledOcticon} from '@primer/react'
import {XIcon} from '@primer/octicons-react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {GitHubLabel} from '../types'
import {API_HOSTNAME} from '../urls'
import {foregroundFor} from '../utils'
import {AutocompleteBase} from './AutocompleteBase'

type LabelSelectorProps = {
  labels: GitHubLabel[]
  setLabels: (labels: GitHubLabel[]) => void
  repo: string
  owner: string
}
export const LabelSelector: React.FC<LabelSelectorProps> = ({labels, setLabels, repo, owner}) => {
  const url = `https://${API_HOSTNAME}/repos/${owner}/${repo}/labels`
  const {data: allLabels} = useGitHubQuery<GitHubLabel[]>(url)

  const allTokens = (allLabels || []).map(label => ({
    ...label,
    text: label.name,
    leadingVisual: labelLeadingVisual(label)
  }))

  return (
    <AutocompleteBase
      tokens={labels}
      setTokens={setLabels}
      allTokens={allTokens}
      onChange={search => console.log({search})}
      tokenComponent={LabelToken}
    />
  )
}

const LabelToken: React.FC<GitHubLabel & {onRemove: () => void}> = label => {
  const color = foregroundFor(label.color)
  return (
    <Label sx={{color, bg: `#${label.color}`}}>
      {label.name}
      <Box onClick={label.onRemove} sx={{display: 'inline', pl: 1, cursor: 'pointer'}}>
        <StyledOcticon icon={XIcon} sx={{color}} />
      </Box>
    </Label>
  )
}

const labelLeadingVisual = (label: GitHubLabel) => {
  const color = `#${label.color}`
  return () => (
    <Box
      bg={color}
      borderColor={color}
      width={14}
      height={14}
      borderRadius={10}
      margin="auto"
      borderWidth="1px"
      borderStyle="solid"
    />
  )
}
