import {Box, PointerBox, Text} from '@primer/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import {GitHubIssue} from '../types'
import {timeAgo} from '../utils'
import {addView, createView} from './navigation'
import {UserAvatar} from './UserAvatar'
import {UserLogin} from './UserLogin'
import remarkGfm from 'remark-gfm'
import remarkGithub from 'remark-github'
import {useNavigation} from '../hooks/useNavigation'
import remarkEmoji from '../plugins/remarkEmoji'

type CommentProps = {
  nwo: string
  comment: GitHubIssue
}
export const Comment: React.FC<CommentProps> = ({nwo, comment}) => {
  const [navigation, setNavigation] = useNavigation()

  const body = (comment.body || '').replace(/<!--[\s\S]*?-->/g, '').trim() // remove html comments

  return (
    <Box display="flex">
      <UserAvatar user={comment.user} sx={{mr: 3}} />

      <PointerBox caret="left-top" flexGrow={1} bg="canvas.subtle">
        <Box
          borderColor="border.default"
          borderBottomWidth={1}
          borderBottomStyle="solid"
          bg="canvas.subtle"
          px={3}
          py={1}
          sx={{
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
          }}
        >
          <Text fontSize={1}>
            <UserLogin user={comment.user} /> commented {timeAgo(comment.created_at)}
          </Text>
        </Box>

        <Box
          fontSize={1}
          py={2}
          px={3}
          backgroundColor="canvas.default"
          borderRadius={6}
          onClick={e => {
            if (e.metaKey) return

            const target = e.target as HTMLElement
            if (target.closest('.markdown') && target.tagName === 'A') {
              const url = target.getAttribute('href')
              if (!url) return
              const view = createView(url)
              if (view) {
                setNavigation(addView(navigation, view))
                e.preventDefault()
              }
            }
          }}
        >
          {!body && <Text sx={{color: 'fg.muted', fontStyle: 'italic'}}>No description provided.</Text>}
          {body && (
            <ReactMarkdown
              className="markdown"
              linkTarget="_blank"
              remarkPlugins={[remarkGfm, [remarkGithub, {repository: nwo, mentionStrong: false}], remarkEmoji]}
            >
              {body}
            </ReactMarkdown>
          )}
        </Box>
      </PointerBox>
    </Box>
  )
}
