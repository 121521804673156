import {Box, StyledOcticon, Text} from '@primer/react'
import {NoEntryFillIcon} from '@primer/octicons-react'
import React from 'react'

type RequestErrorProps = {
  error: Error
}
export const RequestError: React.FC<RequestErrorProps> = ({error}) => {
  return (
    <Box sx={{p: 5, textAlign: 'center'}}>
      <StyledOcticon
        icon={NoEntryFillIcon}
        size={64}
        sx={{
          color: 'danger.fg'
        }}
      />
      <Box sx={{pt: 3}}>
        <Text
          sx={{
            color: 'danger.fg'
          }}
        >
          An error has ocurred: {error.message}
        </Text>
      </Box>
    </Box>
  )
}
