import {Box, Heading, Text} from '@primer/react'
import React from 'react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {GitHubUserFull} from '../types'
import {buildAPIURL} from '../urls'
import {Loading} from './Loading'
import {ViewProps} from './navigation'
import {RequestError} from './RequestError'
import {UserAvatar} from './UserAvatar'

export const User: React.FC<ViewProps> = ({view}) => {
  const {isLoading, error, data: user} = useGitHubQuery<GitHubUserFull>(buildAPIURL('user', view))

  if (isLoading) return <Loading />
  if (error) return <RequestError error={error} />
  if (!user) return <div>Unexpected server response</div>

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 2,
          p: 3
        }}
      >
        <Box sx={{pb: 3, display: 'flex', justifyContent: 'center'}}>
          <Box style={{width: 260}}>
            <UserAvatar user={user} size={260} noLink />
            <Heading as="h5">{user.name}</Heading>
            <Heading as="h5" sx={{fontSize: 2}}>
              @{user.login}
            </Heading>
            <Box sx={{mt: 3}}>
              <Text>{user.bio}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
