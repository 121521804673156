import {BaseStyles, themeGet, ThemeProvider} from '@primer/react'
import React from 'react'
import {QueryClientProvider} from 'react-query'
import {createGlobalStyle} from 'styled-components'
import {Index} from './components/Index'
import {queryClient} from './queryClient'

export default function App() {
  return (
    <ThemeProvider colorMode="day">
      <BaseStyles>
        <QueryClientProvider client={queryClient}>
          <GlobalStyles />
          <Index />
        </QueryClientProvider>
      </BaseStyles>
    </ThemeProvider>
  )
}

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  pre,
  code {
    font-size: 90%;
  }
  pre {
    padding: ${themeGet('space.3')};
    white-space: pre-wrap;
    word-break: break-all;
  }
  code {
    background-color: ${themeGet('colors.canvas.subtle')};
  }
  pre,
  pre code {
    background-color: ${themeGet('colors.canvas.subtle')};
  }

  .markdown img {
    max-width: 100%;
  }

  .markdown p {
    line-break: anywhere;
  }

  .markdown blockquote {
    font-style: italic;
  }

  .anim-scale-in {
    animation-name: scale-in;
    animation-duration: 0.15s;
    animation-timing-function: cubic-bezier(0.2, 0, 0.13, 1.5);
  }

  @keyframes scale-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes delayed-fade-in {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
