import {useAtom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'
import {createView, Navigation, Section} from '../components/navigation'
import {uuidv4} from '../utils'

export const DEFAULT_SECTIONS: Section[] = [
  {
    id: uuidv4(),
    name: 'Open Source projects',
    type: 'heading',
    views: []
  },
  {
    id: uuidv4(),
    type: 'issues',
    name: 'Node.js issues',
    views: [createView('https://github.com/nodejs/node/issues')!]
  },
  {
    id: uuidv4(),
    type: 'issues',
    name: 'Ruby issues',
    views: [createView('https://github.com/ruby/ruby/issues')!]
  }
]

const navigationAtom = atomWithStorage<Navigation>('navigation', {
  sections: DEFAULT_SECTIONS,
  currentSectionId: DEFAULT_SECTIONS[1].id
})

export const useNavigation = () => useAtom(navigationAtom)
