import {Avatar} from '@primer/react'
import {SystemStyleObject} from '@styled-system/css'
import React from 'react'
import {useNavigation} from '../hooks/useNavigation'
import {GitHubUser} from '../types'
import {addViewFromURL} from './navigation'

type UserAvatarProps = {
  user: GitHubUser
  size?: number
  noLink?: boolean
  sx?: SystemStyleObject
}
export const UserAvatar: React.FC<UserAvatarProps> = ({user, size, noLink, sx}) => {
  const [navigation, setNavigation] = useNavigation()

  if (noLink) return <Avatar sx={{mr: 3, flexShrink: 0}} size={size || 32} src={user.avatar_url} />
  return (
    <Avatar
      sx={{...sx, flexShrink: 0, cursor: 'pointer'}}
      size={size || 32}
      src={user.avatar_url}
      onClick={() => {
        if (!navigation || !setNavigation) return
        setNavigation(addViewFromURL(navigation, user.html_url))
      }}
    />
  )
}
