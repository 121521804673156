export type PatternType = 'issues' | 'issue' | 'issue-new' | 'issue-timeline' | 'pull' | 'user' | 'blob'

type Pattern = {
  type: PatternType
  path: string
  build?: (info: URLInfo, url: string) => string
}

export type URLInfo = {
  type: PatternType
  hostname: string
  html_url: string
  params: Record<string, string>
  title: string
  query: Record<string, string>
}

export const API_HOSTNAME = 'api.github.com'
export const HTML_HOSTNAME = 'github.com'

const ignoredURLs = new Set([
  'https://github.com/features',
  'https://github.com/security',
  'https://github.com/team',
  'https://github.com/enterprise',
  'https://github.com/customer-stories',
  'https://github.com/readme',
  'https://github.com/pricing',
  'https://github.com/team',
  'https://github.com/about'
])

const urls: Pattern[] = [
  {
    type: 'issue-new',
    path: '/:owner/:repo/issues/new'
  },
  {
    type: 'issue',
    path: '/:owner/:repo/issues/:number'
  },
  {
    type: 'pull',
    path: '/:owner/:repo/pull/:number'
  },
  {
    type: 'issues',
    path: '/:owner/:repo/issues'
  },
  {
    type: 'user',
    path: '/:user'
  },
  {
    type: 'blob',
    path: '/:owner/:repo/blob/:ref/*'
  }
]

const endpoints: Pattern[] = [
  {
    type: 'issue',
    path: '/repos/:owner/:repo/issues/:number'
  },
  {
    type: 'issue-timeline',
    path: '/repos/:owner/:repo/issues/:number/timeline'
  },
  {
    type: 'pull',
    path: '/repos/:owner/:repo/pulls/:number'
  },
  {
    type: 'issues',
    path: '/repos/:owner/:repo/issues',
    build(info, url) {
      const {q} = info.query
      if (q) {
        // This regexp parses this and finds 4 tokens
        // is:issue is:open label:"web streams",foo foo
        const tokens = q.match(/\S*("[^"]+")\S*|\S+/g) || []
        const constraints: Record<string, string[]> = {}
        for (const token of tokens) {
          const index = token.indexOf(':')
          if (index > 0) {
            const key = token.substring(0, index)
            const value = token.substring(index + 1)
            constraints[key] = constraints[key] || []
            constraints[key].push(value)
          } else {
            constraints[''] = constraints[''] || []
            constraints[''].push(token)
          }
        }
        let search = '?'
        for (const [key, value] of Object.entries(constraints)) {
          if (key === 'label') {
            const quotesRemoved = value.map(label => {
              if (label.startsWith('"') && label.endsWith('"')) return label.substring(1, label.length - 1)
              return label
            })
            search += `labels=${encodeURIComponent(quotesRemoved.join(','))}`
          }
        }
        return `${url}${search}`
      }
      return url
    }
  },
  {
    type: 'user',
    path: '/users/:user'
  },
  {
    type: 'blob',
    path: '/repos/:owner/:repo/contents/:_?ref=:ref'
  }
]

export function parseURL(url: string): URLInfo | undefined {
  if (url.endsWith('/')) url = url.substring(0, url.length - 1)
  if (ignoredURLs.has(url)) return

  const anchor = document.createElement('a')
  anchor.href = url
  const path = anchor.pathname.split('/').slice(1)
  if (anchor.hostname !== HTML_HOSTNAME) return

  for (const candidate of urls) {
    const components = candidate.path.split('/').slice(1)
    const params = matches(path, components)
    if (params) {
      const info = {
        type: candidate.type,
        html_url: url,
        title: '',
        params,
        hostname: anchor.hostname,
        query: parseSearchString(anchor.search)
      }
      info.title = titleURL(info)
      return info
    }
  }
}

function parseSearchString(search?: string): Record<string, string> {
  if (!search) return {}
  const parts = search.substring(1).split('&')
  const result: Record<string, string> = {}
  for (const part of parts) {
    const [key, value] = part.split('=')
    result[key] = decodeURIComponent(value || '')
  }
  return result
}

export function removeQueryString(url: string) {
  const index = url.indexOf('?')
  if (index > 0) return url.substring(0, index)
  return url
}

function matches(path: string[], components: string[]) {
  const info: Record<string, string> = {}
  let i = 0
  for (const component of components) {
    if (!path[i]) return null
    if (component === '*') {
      info['_'] = path.slice(i).join('/')
      return info
    }
    if (component.startsWith(':')) {
      const name = component.substring(1)
      info[name] = path[i]
    } else if (component !== path[i]) {
      return null
    }
    i++
  }
  if (path.length > components.length) return null
  return info
}

export function buildAPIURL(type: PatternType, base: URLInfo, params?: Record<string, string>) {
  return buildURL(type, API_HOSTNAME, endpoints, base, params)
}

export function buildHTMLURL(type: PatternType, base: URLInfo, params?: Record<string, string>) {
  return buildURL(type, HTML_HOSTNAME, urls, base, params)
}

function buildURL(
  type: PatternType,
  hostname: string,
  patterns: Pattern[],
  base: URLInfo,
  params?: Record<string, string>
) {
  const pattern = patterns.find(typ => typ.type === type)
  if (!pattern) {
    console.log('Could not build URL for ', hostname, base.type)
    return ''
  }
  let {path} = pattern
  const allParams = {...base.params, ...params}
  for (const param in allParams) {
    path = path.replace(`:${param}`, allParams[param])
  }
  const url = `https://${hostname}${path}`
  if (pattern.build) {
    return pattern.build(base, url)
  }
  return url
}

export function titleURL(info: URLInfo): string {
  const {type, params} = info
  const nwo = getNameWithOnwer(info)
  if (type === 'issues') {
    return `${nwo} issues`
  }
  if (type === 'issue' || type === 'pull') {
    return `${nwo} #${params['number']}`
  }
  if (type === 'user') {
    return `@${params['user']}`
  }
  if (type === 'issue-new') {
    return `${nwo} new issue`
  }
  return ''
}

export function getNameWithOnwer(info: URLInfo) {
  return `${info.params['owner'] || ''}/${info.params['repo'] || ''}`
}
