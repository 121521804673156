import {Box, ButtonPrimary, PointerBox, Text, TextInput, themeGet} from '@primer/react'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {useGitHubMutation} from '../hooks/useGitHubMutation'
import {useNavigation} from '../hooks/useNavigation'
import {useSession} from '../hooks/useSession'
import {queryClient} from '../queryClient'
import {GitHubIssue, GitHubLabel, GitHubMilestone, GitHubUser} from '../types'
import {API_HOSTNAME} from '../urls'
import {CollaboratorSelector} from './CollaboratorSelector'
import {LabelSelector} from './LabelSelector'
import {MilestoneSelector} from './MilestoneSelector'
import {replaceView, ViewProps} from './navigation'
import {UserAvatar} from './UserAvatar'

export const IssueNew: React.FC<ViewProps> = ({view}) => {
  const [session] = useSession()
  const [navigation, setNavigation] = useNavigation()
  const owner = view.params['owner']
  const repo = view.params['repo']
  const url = `https://${API_HOSTNAME}/repos/${owner}/${repo}/issues`
  const mutation = useGitHubMutation<GitHubIssue>(url)

  const firstInput = useRef<HTMLInputElement>(null)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [keep, setKeep] = useState(false)

  const [labels, setLabels] = useState<GitHubLabel[]>([])
  const [assignees, setAssignees] = useState<GitHubUser[]>([])
  const [milestone, setMilestone] = useState<GitHubMilestone | null>(null)

  // TODO
  if (!session) return <div></div>

  return (
    <Box display="flex" sx={{p: 2}}>
      <UserAvatar user={session.user} sx={{mr: 3}} size={40} />
      <PointerBox caret="left-top" flexGrow={1} p={1}>
        <Box sx={{p: 1, display: 'flex', flexDirection: 'column'}}>
          <TextInput
            ref={firstInput}
            placeholder="Title"
            contrast
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
          />

          <Box sx={{py: 2}}>
            <Textarea placeholder="Leave a comment" value={body} onChange={e => setBody(e.target.value)} />
          </Box>

          <Box sx={{py: 2, fontSize: 1, mb: 2}}>
            <Box>
              <Text sx={{fontWeight: 'bold'}}>Labels</Text>
            </Box>
            <Box>
              <LabelSelector labels={labels} setLabels={setLabels} owner={owner} repo={repo} />
            </Box>
          </Box>

          <Box sx={{py: 2, fontSize: 1, mb: 2}}>
            <Box>
              <Text sx={{fontWeight: 'bold'}}>Assignees</Text>
            </Box>
            <Box>
              <CollaboratorSelector users={assignees} setUsers={setAssignees} owner={owner} repo={repo} />
            </Box>
          </Box>

          <Box sx={{py: 2, fontSize: 1, mb: 2}}>
            <Box>
              <Text sx={{fontWeight: 'bold'}}>Milestone</Text>
            </Box>
            <Box>
              <MilestoneSelector milestone={milestone} setMilestone={setMilestone} owner={owner} repo={repo} />
            </Box>
          </Box>

          <Box sx={{display: 'flex', alignItems: 'center', mt: 2, fontSize: 1}}>
            <input type="checkbox" checked={keep} onChange={e => setKeep(!keep)} /> Create multiple issues
            <Box sx={{flexGrow: 1}}></Box>
            <ButtonPrimary
              onClick={() =>
                mutation.mutate(
                  {title, body},
                  {
                    onSuccess(issue) {
                      queryClient.refetchQueries([url])
                      if (keep) {
                        setTitle('')
                        setBody('')
                        mutation.reset()
                        firstInput.current?.focus()
                      } else {
                        setNavigation(replaceView(navigation, view.id, issue.url))
                      }
                    }
                  }
                )
              }
            >
              Submit new issue
            </ButtonPrimary>
          </Box>
        </Box>
      </PointerBox>
    </Box>
  )
}

const Textarea = styled.textarea`
  width: 100%;
  height: 300px;
  border-color: ${themeGet('colors.border.muted')};
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  padding: ${themeGet('space.2')};
  background-color: ${themeGet('colors.canvas.inset')};
`
