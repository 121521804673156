import {Box, StyledOcticon, Timeline} from '@primer/react'
import {CheckIcon, CommentIcon, FileDiffIcon, QuestionIcon, TagIcon} from '@primer/octicons-react'
import React from 'react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {GitHubIssue, GitHubTimeline, GitHubTimelineLabeled, GitHubTimelineReviewed} from '../types'
import {buildAPIURL, getNameWithOnwer} from '../urls'
import {timeAgo} from '../utils'
import {Comment} from './Comment'
import {LabelComponent} from './LabelComponent'
import {Loading} from './Loading'
import {View} from './navigation'
import {RequestError} from './RequestError'
import {UserAvatar} from './UserAvatar'
import {UserLogin} from './UserLogin'

type TimelineComponentProps = {
  view: View
  issue: GitHubIssue
}
export const TimelineComponent: React.FC<TimelineComponentProps> = ({view, issue}) => {
  const {isLoading, error, data} = useGitHubQuery<GitHubTimeline[]>(buildAPIURL('issue-timeline', view))

  if (isLoading) return <Loading />
  if (error) return <RequestError error={error} />

  const nwo = getNameWithOnwer(view)
  let prevTimelineItem: GitHubTimeline

  return (
    <Timeline sx={{pb: 3}}>
      {data!.map(item => {
        const needsDivider =
          item.event === 'commented' && (!prevTimelineItem || prevTimelineItem?.event === 'commented')
        let elem: JSX.Element | null = null

        if (item.event === 'commented') {
          elem = <Comment nwo={nwo} comment={item as unknown as GitHubIssue} />
        } else if (item.event === 'labeled') {
          elem = <LabeledEvent issue={issue} item={item} />
        } else if (item.event === 'reviewed') {
          elem = <ReviewedEvent item={item} />
        }

        if (!elem) {
          // console.log('Unsupported timeline event type', item.event)
          return null
        }

        prevTimelineItem = item
        return (
          <Box key={item.node_id}>
            {needsDivider && <Timeline.Item sx={{ml: 8}} />}
            {elem}
          </Box>
        )
      })}
    </Timeline>
  )
}

type LabeledEventProps = {
  issue: GitHubIssue
  item: GitHubTimelineLabeled
}
const LabeledEvent: React.FC<LabeledEventProps> = ({issue, item}) => {
  const {label, actor} = item
  return (
    <Timeline.Item sx={{ml: 8}}>
      <Timeline.Badge sx={{bg: 'canvas.subtle'}}>
        <StyledOcticon icon={TagIcon} sx={{color: 'fg.subtle'}} />
      </Timeline.Badge>

      <Timeline.Body>
        <UserAvatar user={actor} size={20} /> <UserLogin user={actor} /> added the{' '}
        <LabelComponent issue={issue} label={label} /> label {timeAgo(item.created_at)}
      </Timeline.Body>
    </Timeline.Item>
  )
}

type State = {
  bgColor: string
  fgColor: string
  text: string
  icon: React.ElementType
}
const states: Record<string, State> = {
  approved: {
    bgColor: 'success.emphasis',
    fgColor: 'fg.onEmphasis',
    text: 'approved these changes',
    icon: CheckIcon
  },
  changes_requested: {
    bgColor: 'danger.emphasis',
    fgColor: 'fg.onEmphasis',
    text: 'requested changes',
    icon: FileDiffIcon
  },
  commented: {
    bgColor: 'canvas.subtle',
    fgColor: 'fg.subtle',
    text: 'commented',
    icon: CommentIcon
  }
}

type ReviewedEventProps = {
  item: GitHubTimelineReviewed
}
const ReviewedEvent: React.FC<ReviewedEventProps> = ({item}) => {
  const {state, user} = item

  let stateInfo: State = states[state]
  if (!stateInfo) {
    stateInfo = {
      bgColor: 'canvas.subtle',
      fgColor: 'fg.subtle',
      icon: QuestionIcon,
      text: state
    }
  }

  return (
    <Timeline.Item sx={{ml: 8}}>
      <Timeline.Badge sx={{bg: stateInfo.bgColor}}>
        <StyledOcticon icon={stateInfo.icon} sx={{color: stateInfo.fgColor}} />
      </Timeline.Badge>

      <Timeline.Body>
        <UserAvatar user={user} size={20} /> <UserLogin user={user} /> {stateInfo.text} {timeAgo(item.submitted_at)}
        {/* {item.body} */}
      </Timeline.Body>
    </Timeline.Item>
  )
}
