import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {Heading, SideNav, StyledOcticon, Text} from '@primer/react'
import {GitPullRequestIcon, IssueOpenedIcon, ProjectIcon} from '@primer/octicons-react'
import {Navigation, Section, setCurrentSectionId} from './navigation'

type SidebarSectionProps = {
  section: Section
  navigation: Navigation
  setNavigation: (navigation: Navigation) => void
  id: string
}
export const SidebarSection: React.FC<SidebarSectionProps> = props => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: props.id})
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    height: 36,
    paddingTop: props.section.type === 'heading' ? 8 : 0
  }

  return (
    <>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {props.section.type === 'heading' && <SidebarSectionHeading {...props} />}
        {props.section.type !== 'heading' && <SidebarSectionItem {...props} />}
      </div>
    </>
  )
}

const SidebarSectionHeading: React.FC<SidebarSectionProps> = ({section}) => {
  return (
    <Heading as="h5" sx={{fontSize: 1}} color="fg.default">
      {section.name}
    </Heading>
  )
}

export const SidebarSectionItem: React.FC<SidebarSectionProps> = ({section, navigation, setNavigation}) => {
  let icon: React.ElementType<any> | null = null
  if (section.type === 'issues') {
    icon = IssueOpenedIcon
  } else if (section.type === 'pull') {
    icon = GitPullRequestIcon
  } else if (section.type === 'project') {
    icon = ProjectIcon
  }
  const selected = navigation.currentSectionId === section.id
  const color = selected ? 'fg.onEmphasis' : 'fg.muted'
  return (
    <SideNav.Link
      variant="full"
      onClick={() => setNavigation(setCurrentSectionId(navigation, section.id))}
      sx={{
        px: 3,
        py: 2,
        borderRadius: 2,
        cursor: 'pointer',
        color,
        backgroundColor: selected ? 'accent.emphasis' : '',
        transition: 'background-color 0.05s',
        ':hover': {
          color: 'fg.muted',
          backgroundColor: 'accent.muted',
          textDecoration: 'none'
        }
      }}
    >
      {icon && <StyledOcticon sx={{mr: 2}} size={16} icon={icon} />}
      <Text style={{flexGrow: 1}}>{section.name}</Text>
      {/* <CounterLabel sx={{ml: 1}}>{section.count}</CounterLabel> */}
    </SideNav.Link>
  )
}
