import {Box, ButtonClose, StyledOcticon, Text, Truncate} from '@primer/react'
import {LinkExternalIcon, StarIcon} from '@primer/octicons-react'
import {useLayoutEffect, useRef} from 'react'
import {useNavigation} from '../hooks/useNavigation'
import {AddView} from './AddView'
import {Issue} from './Issue'
import {IssueNew} from './IssueNew'
import {IssuesList} from './IssuesList'
import {addViewFromURL, closeView, currentSection, setViewCollapsed, ViewProps} from './navigation'
import {Sidebar} from './Sidebar'
import {User} from './User'

export function Index() {
  const [navigation, setNavigation] = useNavigation()
  const scrollableArea = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const area = scrollableArea.current
    if (!area) return
    area.scroll({left: area.scrollWidth, behavior: 'smooth'})
  }, [navigation])

  const section = currentSection(navigation)

  return (
    <>
      <Box display="flex" sx={{height: '100vh', width: '100vw', backgroundColor: 'canvas.default'}}>
        <Sidebar />
        <Box flexGrow={1} height="100%" overflowX="scroll">
          <Box display="flex" overflowX="auto" width="100%" height="100%" ref={scrollableArea}>
            {section && section.views.map(view => <ViewBox key={view.id} view={view} />)}

            <Box sx={{flexGrow: 1}} />
            <Box sx={{display: 'flex', alignItems: 'center', mx: 2}}>
              <AddView onViewAdded={url => setNavigation(addViewFromURL(navigation, url))} />
            </Box>
            <Box sx={{flexGrow: 1}} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

const ViewBox: React.FC<ViewProps> = ({view}) => {
  const [navigation, setNavigation] = useNavigation()

  if (view.collapsed) {
    return (
      <Box
        height="100%"
        borderColor="border.default"
        borderRightWidth={1}
        borderRightStyle="solid"
        flexShrink={0}
        display="flex"
        flexDirection="column"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        bg="canvas.subtle"
        px="2"
        py="2"
        width={32}
      >
        <ButtonClose onClick={() => setNavigation(closeView(navigation, view.id))} />
        <Box
          sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}
          onClick={() => setNavigation(setViewCollapsed(navigation, view.id, false))}
        >
          <Box sx={{transform: 'translateX(-45%) rotate(-90deg)', position: 'relative'}}>
            <Truncate sx={{maxWidth: '30vw'}} title={view.title}>
              {view.title}
            </Truncate>
          </Box>
        </Box>
        <StyledOcticon icon={StarIcon} size={16} />
      </Box>
    )
  }

  return (
    <Box
      width="40vw"
      height="100%"
      borderColor="border.default"
      borderRightWidth={1}
      borderRightStyle="solid"
      overflowY="auto"
      flexShrink={0}
    >
      <Box
        sx={{
          display: 'flex',
          alignContent: 'right',
          position: 'sticky',
          top: '0',
          borderColor: 'border.default',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          bg: 'canvas.subtle',
          px: 3,
          py: 2,
          alignItems: 'center'
        }}
      >
        <a href={view.html_url} target="_blank" rel="noopener noreferrer">
          <StyledOcticon icon={LinkExternalIcon} size={16} sx={{mr: 2, mt: 1}} />
        </a>
        {/* <StyledOcticon icon={StarIcon} size={16} sx={{mr: 2}} />
        <StyledOcticon icon={ArrowUpIcon} size={16} sx={{mr: 2}} />
        <StyledOcticon icon={ArrowDownIcon} size={16} sx={{mr: 2}} /> */}
        <Text
          sx={{flexGrow: 1, fontSize: 1}}
          onClick={() => setNavigation(setViewCollapsed(navigation, view.id, true))}
        >
          {view.title}
        </Text>
        <ButtonClose onClick={() => setNavigation(closeView(navigation, view.id))} />
      </Box>
      <ViewComponent view={view} />
    </Box>
  )
}

const ViewComponent: React.FC<ViewProps> = ({view}) => {
  if (view.type === 'issue' || view.type === 'pull') return <Issue view={view} />
  if (view.type === 'issues') return <IssuesList view={view} />
  if (view.type === 'user') return <User view={view} />
  if (view.type === 'issue-new') return <IssueNew view={view} />

  return <div>Unknown view type {view.type}</div>
}
