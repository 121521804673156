import {useMutation} from 'react-query'
import {headers} from '../auth'

export function useGitHubMutation<T>(url: string) {
  return useMutation<T, Error, Record<string, string>>(url, data =>
    fetch(url, {
      headers: headers(),
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => {
      if (!res.ok) throw new Error(res.statusText)
      return res.json()
    })
  )
}
