import {visit} from 'unist-util-visit'
import {emojis} from './emojis'

const regex = /:\+1:|:-1:|:[\w-]+:/g

export default function plugin(options: object) {
  return (tree: any) => {
    visit(tree, 'text', function (node: {value: string}) {
      node.value = replaceEmojis(node.value)
    })
  }
}

export function replaceEmojis(value: string) {
  return value.replace(regex, match => {
    const val = match.substring(1, match.length - 1)
    const emoji = emojis[val]
    if (!emoji) return match
    if (emoji.startsWith('https')) return match // TODO
    return emojis[val] || match
  })
}
