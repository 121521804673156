import {Text} from '@primer/react'
import React from 'react'
import {useNavigation} from '../hooks/useNavigation'
import {GitHubUser} from '../types'
import {addViewFromURL} from './navigation'

type UserLoginProps = {
  user: GitHubUser
  fontRegular?: boolean
}
export const UserLogin: React.FC<UserLoginProps> = ({user, fontRegular}) => {
  const [navigation, setNavigation] = useNavigation()
  return (
    <Text
      sx={{
        fontWeight: fontRegular ? 'regular' : 'bold',
        cursor: 'pointer',
        ':hover': {
          color: fontRegular ? 'accent.emphasis' : undefined
        }
      }}
      onClick={() => setNavigation(addViewFromURL(navigation, user.html_url))}
    >
      {user.login}
    </Text>
  )
}
