import React from 'react'
import {Box, Spinner} from '@primer/react'

export const Loading = () => {
  return (
    <Box sx={{animation: 'delayed-fade-in 500ms forwards', justifyContent: 'center', display: 'flex', p: 3}}>
      <Spinner />
    </Box>
  )
}
