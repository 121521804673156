import {Box, BranchName, Heading, Text} from '@primer/react'
import React from 'react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {StateIcon} from '../issue-states'
import {replaceEmojis} from '../plugins/remarkEmoji'
import {GitHubIssue, GitHubPullRequest} from '../types'
import {buildAPIURL, getNameWithOnwer} from '../urls'
import {pluralize, timeAgo} from '../utils'
import {Comment} from './Comment'
import {Loading} from './Loading'
import {ViewProps} from './navigation'
import {RequestError} from './RequestError'
import {TimelineComponent} from './Timeline'
import {UserLogin} from './UserLogin'

export const Issue: React.FC<ViewProps> = ({view}) => {
  const {isLoading, error, data: issue} = useGitHubQuery<GitHubIssue | GitHubPullRequest>(buildAPIURL(view.type, view))

  if (isLoading) return <Loading />
  if (error) return <RequestError error={error} />
  if (!issue) return <div>Unexpected server response</div>

  const pull = 'head' in issue ? issue : null
  const nwo = getNameWithOnwer(view)

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'canvas.default',
          zIndex: 2,
          px: 3,
          py: 2
        }}
      >
        <Box display="flex" borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">
          <Box sx={{pb: 2, mb: 1}}>
            <Box sx={{fontSize: 1, display: 'flex', alignItems: 'start'}}>
              <StateIcon issue={issue} size={32} sx={{mr: 3, mt: 2}} />
              <Box>
                <Heading as="h5" sx={{fontSize: 2}}>
                  {replaceEmojis(issue.title)} <Text sx={{color: 'fg.subtle'}}>#{issue.number}</Text>
                </Heading>
                {!pull && <IssueHeader issue={issue} />}
                {pull && <PullRequestHeader pull={pull} />}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{px: 3, pt: 1}}>
        <Comment comment={issue} nwo={nwo} />

        <TimelineComponent view={view} issue={issue} />
      </Box>
    </>
  )
}

type PullRequestHeaderProps = {
  pull: GitHubPullRequest
}
const PullRequestHeader: React.FC<PullRequestHeaderProps> = ({pull}) => {
  return (
    <>
      <UserLogin user={pull.user} /> wants to merge {pull.commits} {pluralize('commit', pull.commits)}
      <br /> into <BranchName>{pull.base.label}</BranchName> from <BranchName>{pull.head.label}</BranchName>
    </>
  )
}

type IssueHeaderProps = {
  issue: GitHubIssue
}
const IssueHeader: React.FC<IssueHeaderProps> = ({issue}) => {
  return (
    <>
      opened {timeAgo(issue.created_at)} by <UserLogin user={issue.user} />
    </>
  )
}
