export function foregroundFor(colorString: string) {
  const color = parseInt(colorString, 16)
  const r = (color >>> 16) & 0xff
  const g = (color >>> 8) & 0xff
  const b = color & 0xff
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq >= 128 ? 'black' : 'white'
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function timeAgo(date: string) {
  const startedAt = new Date(date)
  const now = new Date()
  const seconds = Math.round((now.getTime() - startedAt.getTime()) / 1000)

  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(days / 365)

  if (minutes < 1) {
    return 'now'
  } else if (hours < 1) {
    return `${minutes} ${pluralize('minute', minutes)} ago`
  } else if (days < 1) {
    return `${hours} ${pluralize('hour', hours)} ago`
  } else if (months < 1) {
    if (days === 1) return 'yesterday'
    return `${days} days ago`
  } else if (years < 1) {
    return `${months} ${pluralize('month', months)} ago`
  }
  return `${years} ${pluralize('year', years)} ago`
}

export function pluralize(str: string, num: number) {
  return num === 1 ? str : `${str}s`
}
