import {Box, Heading, Link, StyledOcticon, Text} from '@primer/react'
import {CommentIcon} from '@primer/octicons-react'
import React from 'react'
import {useNavigation} from '../hooks/useNavigation'
import {StateIcon} from '../issue-states'
import {replaceEmojis} from '../plugins/remarkEmoji'
import {GitHubIssue} from '../types'
import {timeAgo} from '../utils'
import {LabelComponent} from './LabelComponent'
import {addViewFromURL} from './navigation'
import {UserLogin} from './UserLogin'

type IssueListItemProps = {
  issue: GitHubIssue
}
export const IssueListItem: React.FC<IssueListItemProps> = ({issue}) => {
  const [navigation, setNavigation] = useNavigation()
  const url = issue.pull_request ? issue.pull_request.html_url : issue.html_url
  return (
    <Box
      sx={{
        display: 'flex',
        borderColor: 'border.default',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        px: 3,
        ':hover': {
          backgroundColor: 'canvas.subtle'
        }
      }}
    >
      <Text sx={{pr: 2, pt: 2}}>
        <StateIcon issue={issue} size={16} />
      </Text>
      <Box sx={{py: 2, flexGrow: 1}}>
        <Heading as="h5" sx={{fontSize: 2}}>
          <Link
            sx={{mr: 2, color: 'fg.default', cursor: 'pointer'}}
            onClick={() => setNavigation(addViewFromURL(navigation, url))}
          >
            {replaceEmojis(issue.title)}
          </Link>
          {issue.labels
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(label => (
              <LabelComponent key={label.node_id} issue={issue} label={label} sx={{mr: 2}} />
            ))}
        </Heading>
        <Text color="fg.muted" sx={{fontSize: 1}}>
          #{issue.number} opened {timeAgo(issue.created_at)} by <UserLogin user={issue.user} fontRegular />
        </Text>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', flexShrink: 0}}>
        {issue.comments > 0 && (
          <Box sx={{color: 'fg.muted', fontSize: 1}}>
            {issue.comments}
            <StyledOcticon icon={CommentIcon} sx={{ml: 1}} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
