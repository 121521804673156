import {Label} from '@primer/react'
import {SystemStyleObject} from '@styled-system/css'
import React from 'react'
import {useNavigation} from '../hooks/useNavigation'
import {GitHubIssue, GitHubLabel} from '../types'
import {foregroundFor} from '../utils'
import {addViewFromURL} from './navigation'

type LabelComponentProps = {
  issue: GitHubIssue
  label: GitHubLabel
  sx?: SystemStyleObject
}
export const LabelComponent: React.FC<LabelComponentProps> = ({issue, label, sx}) => {
  const [navigation, setNavigation] = useNavigation()
  return (
    <Label
      key={label.node_id}
      variant="medium"
      sx={{
        ...sx,
        color: foregroundFor(label.color),
        bg: `#${label.color}`,
        cursor: 'pointer'
      }}
      onClick={() => {
        const url = `${issue.html_url.replace(/(issues|pull)\/\d+/, 'issues?q=')}${encodeURIComponent(
          `label:"${label.name}"`
        )}`
        setNavigation(addViewFromURL(navigation, url))
      }}
    >
      {label.name}
    </Label>
  )
}
