import {StyledOcticon} from '@primer/react'
import {
  GitMergeIcon,
  GitPullRequestClosedIcon,
  GitPullRequestDraftIcon,
  GitPullRequestIcon,
  IssueClosedIcon,
  IssueDraftIcon,
  IssueOpenedIcon,
  IssueReopenedIcon,
  QuestionIcon
} from '@primer/octicons-react'
import {GitHubIssue, GitHubPullRequest} from './types'
import {SystemStyleObject} from '@styled-system/css'

type IssueState = {
  icon: any
  color: string
}

export const issueStates: Record<'issue' | 'pull', Record<string, IssueState>> = {
  issue: {
    open: {
      icon: IssueOpenedIcon,
      color: 'success.emphasis'
    },
    closed: {
      icon: IssueClosedIcon,
      color: 'done.emphasis'
    },
    draft: {
      icon: IssueDraftIcon,
      color: 'neutral.emphasis'
    },
    reopened: {
      icon: IssueReopenedIcon,
      color: 'success.emphasis'
    }
  },
  pull: {
    open: {
      icon: GitPullRequestIcon,
      color: 'success.emphasis'
    },
    closed: {
      icon: GitPullRequestClosedIcon,
      color: 'danger.emphasis'
    },
    merged: {
      icon: GitMergeIcon,
      color: 'done.emphasis'
    },
    draft: {
      icon: GitPullRequestDraftIcon,
      color: 'neutral.emphasis'
    }
  }
}

export function stateFor(issue: GitHubIssue | GitHubPullRequest) {
  const type = 'head' in issue ? 'pull' : 'issue'
  let state = issue.state
  if ('merged' in issue && issue.merged) state = 'merged'

  return issueStates[type][state]
}

type StateIconProps = {
  issue: GitHubIssue | GitHubPullRequest
  size: number
  sx?: SystemStyleObject
}
export const StateIcon: React.FC<StateIconProps> = ({issue, size, sx}) => {
  const type = issue.pull_request || 'head' in issue ? 'pull' : 'issue'
  let state = issue.state
  if (issue.draft) state = 'draft'
  else if ('merged' in issue && issue.merged) state = 'merged'
  else if (issue.pull_request?.merged_at) state = 'merged'

  let info = issueStates[type][state]
  if (!info) {
    console.log('cannot find icon for', type, state)
    info = {
      icon: QuestionIcon,
      color: 'fg.muted'
    }
  }

  return <StyledOcticon icon={info.icon} sx={{...sx, color: info.color}} size={size} />
}
