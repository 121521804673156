const KEY = 'token'

export function setToken(token: string | null) {
  if (!token) {
    localStorage.removeItem(KEY)
  } else {
    localStorage.setItem(KEY, btoa(token))
  }
}

export function getToken() {
  const raw = localStorage.getItem(KEY)
  try {
    return atob(raw || '')
  } catch (err) {
    return null
  }
}

export function headers(): HeadersInit {
  const token = getToken()
  if (token) {
    return {
      authorization: `token ${token}`
    }
  }
  return {}
}
