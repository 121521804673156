import {arrayMove} from '@dnd-kit/sortable'
import {parseURL, URLInfo} from '../urls'
import {uuidv4} from '../utils'

export type SectionType = 'heading' | 'issues' | 'issue' | 'pull' | 'project'

export type Navigation = {
  currentSectionId?: string
  sections: Section[]
}

export type Section = {
  id: string
  name: string
  type: SectionType
  views: View[]
}

export type View = URLInfo & {
  id: string
  collapsed?: boolean
  parent?: string
}

export type ViewProps = {
  view: View
}

export type push = (url: string) => void

export function closeView(navigation: Navigation, id: string): Navigation {
  const sections = [...navigation.sections]
  sections.forEach((section, i) => {
    if (section.id === navigation.currentSectionId) {
      sections[i] = {
        ...section,
        views: section.views.filter(view => view.id !== id)
      }
    }
  })
  return {
    ...navigation,
    sections
  }
}

export function addViewFromURL(navigation: Navigation, url: string): Navigation {
  const view = createView(url)
  if (!view) {
    console.log('Could not create view for URL', url)
    return navigation
  }
  return addView(navigation, view)
}

export function addView(navigation: Navigation, view: View): Navigation {
  const sections = [...navigation.sections]
  sections.forEach((section, i) => {
    if (section.id === navigation.currentSectionId) {
      sections[i] = {
        ...section,
        views: section.views.concat(view)
      }
    }
  })

  return {
    ...navigation,
    sections
  }
}

export function createView(url: string): View | undefined {
  const info = parseURL(url)
  if (!info) {
    console.log('Could not create a view for', url)
    return
  }
  return {
    id: uuidv4(),
    ...info
  }
}

export function setCurrentSectionId(navigation: Navigation, id: string): Navigation {
  return {
    ...navigation,
    currentSectionId: id
  }
}

export function addSection(navigation: Navigation, section: Section): Navigation {
  return {
    ...navigation,
    sections: navigation.sections.concat(section)
  }
}

export function currentSection(navigation: Navigation): Section | undefined {
  return navigation.sections.find(section => section.id === navigation.currentSectionId)
}

export function moveSections(navigation: Navigation, activeId: string, overId: string): Navigation {
  if (activeId === overId) return navigation

  const oldIndex = navigation.sections.findIndex(section => section.id === activeId)
  const newIndex = navigation.sections.findIndex(section => section.id === overId)

  return {
    ...navigation,
    sections: arrayMove(navigation.sections, oldIndex, newIndex)
  }
}

export function setViewCollapsed(navigation: Navigation, id: string, collapsed: boolean): Navigation {
  const sections = [...navigation.sections]
  sections.forEach((section, i) => {
    if (section.id === navigation.currentSectionId) {
      sections[i] = {
        ...section,
        views: section.views.map(view => {
          if (view.id !== id) return view
          return {
            ...view,
            collapsed
          }
        })
      }
    }
  })

  return {
    ...navigation,
    sections
  }
}

export function replaceView(navigation: Navigation, id: string, url: string): Navigation {
  const replacement = createView(url)
  if (!replacement) return navigation

  const sections = [...navigation.sections]
  sections.forEach((section, i) => {
    if (section.id === navigation.currentSectionId) {
      sections[i] = {
        ...section,
        views: section.views.map(view => {
          if (view.id !== id) return view
          return replacement
        })
      }
    }
  })

  return {
    ...navigation,
    sections
  }
}

export function changeViewURL(navigation: Navigation, id: string, url: string): Navigation {
  const info = createView(url)
  if (!info) return navigation

  const sections = [...navigation.sections]
  sections.forEach((section, i) => {
    if (section.id === navigation.currentSectionId) {
      sections[i] = {
        ...section,
        views: section.views.map(view => {
          if (view.id !== id) return view
          return {
            ...info,
            id
          }
        })
      }
    }
  })

  return {
    ...navigation,
    sections
  }
}
