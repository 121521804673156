import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {Box, ButtonInvisible, ButtonOutline, Link, SideNav, StyledOcticon, Text} from '@primer/react'
import {LinkExternalIcon, SignOutIcon} from '@primer/octicons-react'
import React, {useState} from 'react'
import {getToken, setToken} from '../auth'
import {queryFunction, useGitHubQuery} from '../hooks/useGitHubQuery'
import {useNavigation} from '../hooks/useNavigation'
import {GitHubUserFull} from '../types'
import {API_HOSTNAME} from '../urls'
import {uuidv4} from '../utils'
import {addSection, moveSections} from './navigation'
import {SidebarSection} from './SidebarSection'
import {UserAvatar} from './UserAvatar'
import {useSession} from '../hooks/useSession'
import {queryClient} from '../queryClient'

export const Sidebar: React.FC = () => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const [navigation, setNavigation] = useNavigation()
  const {sections} = navigation

  const [signedIn, setSignedIn] = useState(!!getToken())

  return (
    <SideNav
      aria-label="Main"
      variant="lightweight"
      sx={{
        px: 3,
        borderColor: 'border.default',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        overflow: 'auto',
        width: 300,
        minWidth: 300,
        borderRadius: 0
      }}
    >
      <Box
        sx={{
          py: 2,
          mb: 4,
          borderColor: 'border.default',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          display: 'flex',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'canvas.subtle'
        }}
      >
        {signedIn && <UserInfo onSignOut={() => setSignedIn(false)} />}
        {!signedIn && <SignIn onSignIn={() => setSignedIn(true)} />}
      </Box>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={sections} strategy={verticalListSortingStrategy}>
          {sections.map((section, i) => (
            <SidebarSection
              key={section.id}
              id={section.id}
              section={section}
              navigation={navigation}
              setNavigation={setNavigation}
            />
          ))}
        </SortableContext>
      </DndContext>

      <Box sx={{p: 3}}></Box>

      <Box sx={{py: 2, display: 'flex'}}>
        <AddHeader
          onHeaderAdded={value =>
            setNavigation(
              addSection(navigation, {
                id: uuidv4(),
                name: value,
                type: 'heading',
                views: []
              })
            )
          }
        />
      </Box>
      <Box sx={{py: 2, display: 'flex'}}>
        <AddSection
          onSectionAdded={value =>
            setNavigation(
              addSection(navigation, {
                id: uuidv4(),
                name: value,
                type: 'issues',
                views: []
              })
            )
          }
        />
      </Box>

      <Box sx={{py: 2, pt: 4, textAlign: 'center', fontSize: 1}}>
        <Link href="https://github.com/gimenete/lionfish.app" target="_blank" rel="noopener noreferrer">
          Report a bug or request a feature <LinkExternalIcon />
        </Link>
      </Box>
    </SideNav>
  )

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event
    if (!over) return
    setNavigation(moveSections(navigation, active.id, over.id))
  }
}

type AddHeaderProps = {
  onHeaderAdded: (name: string) => void
}
const AddHeader: React.FC<AddHeaderProps> = ({onHeaderAdded}) => {
  return (
    <ButtonOutline
      sx={{flexGrow: 1}}
      onClick={() => {
        const value = prompt('Name')
        if (value) {
          onHeaderAdded(value)
        }
      }}
    >
      Add header
    </ButtonOutline>
  )
}

type AddSectionProps = {
  onSectionAdded: (name: string) => void
}
const AddSection: React.FC<AddSectionProps> = ({onSectionAdded}) => {
  return (
    <ButtonOutline
      sx={{flexGrow: 1}}
      onClick={() => {
        const value = prompt('Name')
        if (value) {
          onSectionAdded(value)
        }
      }}
    >
      Add section
    </ButtonOutline>
  )
}

type UserInfoProps = {
  onSignOut: () => void
}
const UserInfo: React.FC<UserInfoProps> = ({onSignOut}) => {
  const {data: user, error} = useGitHubQuery<GitHubUserFull>(`https://${API_HOSTNAME}/user`)

  const [, setSession] = useSession()

  if (error?.message === 'Unauthorized') {
    setSession(null)
    onSignOut()
  }

  // TODO
  if (!user) return <div></div>

  return (
    <>
      <UserAvatar user={user} sx={{mr: 2}} size={32} />
      <Text sx={{flexGrow: 1}}>@{user.login}</Text>

      <ButtonInvisible
        sx={{p: 0}}
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          if (confirm('Are you sure?')) {
            setToken(null)
            setSession(null)
            onSignOut()
          }
        }}
      >
        <StyledOcticon icon={SignOutIcon} size={16} />
      </ButtonInvisible>
    </>
  )
}

type SignInProps = {
  onSignIn: () => void
}
const SignIn: React.FC<SignInProps> = ({onSignIn}) => {
  const [, setSession] = useSession()
  return (
    <>
      <ButtonOutline
        sx={{flexGrow: 1}}
        onClick={async () => {
          const token = prompt('Enter your PAT')
          if (token) {
            try {
              setToken(token)
              const user = await queryClient.fetchQuery('user', () =>
                queryFunction<GitHubUserFull>(`https://${API_HOSTNAME}/user`)
              )
              setSession({user})
              onSignIn()
            } catch (err) {
              console.log('err:', err)
              setToken(null)
              alert('Could not validate token. Please, try again')
            }
          }
        }}
      >
        Sign in!
      </ButtonOutline>
    </>
  )
}
