import {useAtom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'
import {GitHubUser} from '../types'

type Session = {
  user: GitHubUser
}

const sessionAtom = atomWithStorage<Session | null>('session', null)

export const useSession = () => useAtom(sessionAtom)
