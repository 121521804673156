import {ButtonOutline, StyledOcticon} from '@primer/react'
import {PlusIcon} from '@primer/octicons-react'
import React from 'react'

type AddViewProps = {
  onViewAdded: (url: string) => void
}
export const AddView: React.FC<AddViewProps> = ({onViewAdded}) => {
  return (
    <ButtonOutline
      variant="large"
      onClick={() => {
        const url = prompt('URL')
        if (url) {
          onViewAdded(url)
        }
      }}
    >
      <StyledOcticon icon={PlusIcon} size={32} />
    </ButtonOutline>
  )
}
