import {Box, Button, Dropdown, FilteredSearch, StyledOcticon, Text, TextInput} from '@primer/react'
import {FilterIcon, SearchIcon} from '@primer/octicons-react'
import React, {ChangeEvent, useState} from 'react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {useNavigation} from '../hooks/useNavigation'
import {useSession} from '../hooks/useSession'
import {GitHubIssue, GitHubLabel, GitHubMilestone, GitHubUser} from '../types'
import {buildAPIURL, buildHTMLURL, removeQueryString} from '../urls'
import {CollaboratorSelector} from './CollaboratorSelector'
import {IssueListItem} from './IssueListItem'
import {LabelSelector} from './LabelSelector'
import {Loading} from './Loading'
import {MilestoneSelector} from './MilestoneSelector'
import {addViewFromURL, changeViewURL, ViewProps} from './navigation'
import {RequestError} from './RequestError'

export const IssuesList: React.FC<ViewProps> = ({view}) => {
  const [session] = useSession()
  const [navigation, setNavigation] = useNavigation()
  const {isLoading, error, data: issues} = useGitHubQuery<GitHubIssue[]>(buildAPIURL('issues', view))
  const owner = view.params['owner']
  const repo = view.params['repo']

  let content
  if (isLoading) content = <Loading />
  else if (error) content = <RequestError error={error} />
  else if (issues) content = issues.map(issue => <IssueListItem key={issue.node_id} issue={issue} />)

  return (
    <>
      <Box
        sx={{
          px: 3,
          py: 2,
          display: 'flex',
          borderWidth: 1,
          borderBottomStyle: 'solid',
          borderColor: 'border.default',
          position: 'sticky',
          backgroundColor: 'canvas.default',
          top: 0
        }}
      >
        <IssuesListSearch
          owner={owner}
          repo={repo}
          query={view.query['q']}
          onChange={search => {
            const url = `${removeQueryString(view.html_url)}?q=${encodeURIComponent(search)}`
            setNavigation(changeViewURL(navigation, view.id, url))
          }}
        />
        <Box sx={{flexGrow: 1}}></Box>
        {session && (
          <Button
            variant="small"
            sx={{textAlign: 'right', ml: 2}}
            onClick={() => {
              const url = buildHTMLURL('issue-new', view)
              setNavigation(addViewFromURL(navigation, url))
            }}
          >
            New issue
          </Button>
        )}
      </Box>
      {content}
    </>
  )
}

type IssuesListSearchProps = {
  owner: string
  repo: string
  query: string
  onChange: (q: string) => void
}
const IssuesListSearch: React.FC<IssuesListSearchProps> = ({owner, repo, query, onChange}) => {
  const [open, setOpen] = useState(false)
  const [labels, setLabels] = useState<GitHubLabel[]>([])
  const [assignees, setAssignees] = useState<GitHubUser[]>([])
  const [milestone, setMilestone] = useState<GitHubMilestone | null>(null)
  const [search, setSearch] = useState(query)

  return (
    <FilteredSearch>
      <Dropdown>
        <Dropdown.Button variant="small" onClick={() => setOpen(!open)}>
          <StyledOcticon icon={FilterIcon} size={16} />
        </Dropdown.Button>
        <Dropdown.Menu direction="se" className={open ? 'anim-scale-in' : ''}>
          <Box sx={{p: 2, fontSize: 1, mb: 2}}>
            <Box>
              <Text sx={{fontWeight: 'bold'}}>Labels</Text>
            </Box>
            <Box>
              <LabelSelector labels={labels} setLabels={setLabels} owner={owner} repo={repo} />
            </Box>
          </Box>

          <Box sx={{p: 2, fontSize: 1, mb: 2}}>
            <Box>
              <Text sx={{fontWeight: 'bold'}}>Assignees</Text>
            </Box>
            <Box>
              <CollaboratorSelector users={assignees} setUsers={setAssignees} owner={owner} repo={repo} />
            </Box>
          </Box>

          <Box sx={{p: 2, fontSize: 1, mb: 2}}>
            <Box>
              <Text sx={{fontWeight: 'bold'}}>Milestone</Text>
            </Box>
            <Box>
              <MilestoneSelector milestone={milestone} setMilestone={setMilestone} owner={owner} repo={repo} />
            </Box>
          </Box>
        </Dropdown.Menu>
      </Dropdown>
      <form
        onSubmit={e => {
          e.preventDefault()
          onChange(search)
        }}
      >
        <TextInput
          variant="small"
          icon={SearchIcon}
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />
      </form>
    </FilteredSearch>
  )
}
