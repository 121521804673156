import {Button, SelectMenu} from '@primer/react'
import {XIcon} from '@primer/octicons-react'
import {useGitHubQuery} from '../hooks/useGitHubQuery'
import {GitHubMilestone} from '../types'
import {API_HOSTNAME} from '../urls'

type MilestoneSelectorProps = {
  milestone: GitHubMilestone | null
  setMilestone: (milestone: GitHubMilestone | null) => void
  repo: string
  owner: string
}
export const MilestoneSelector: React.FC<MilestoneSelectorProps> = ({milestone, setMilestone, repo, owner}) => {
  const url = `https://${API_HOSTNAME}/repos/${owner}/${repo}/milestones`
  const {data: milestones} = useGitHubQuery<GitHubMilestone[]>(url)

  return (
    <SelectMenu>
      <Button as="summary">{milestone ? milestone.title : 'None selected'}</Button>
      <SelectMenu.Modal>
        <SelectMenu.List>
          {milestone && (
            <SelectMenu.Divider sx={{cursor: 'pointer'}} onClick={() => setMilestone(null)}>
              <XIcon /> Clear milestone
            </SelectMenu.Divider>
          )}
          {milestones?.map(mil => (
            <SelectMenu.Item key={mil.id} selected={mil.id === milestone?.id} onClick={() => setMilestone(mil)}>
              {mil.title}
            </SelectMenu.Item>
          ))}
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  )
}
