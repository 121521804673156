import {Autocomplete, TextInputWithTokens} from '@primer/react'
import React from 'react'

type AutocompleteBaseProps<T> = {
  allTokens: T[]
  tokens: T[]
  setTokens: (tokens: T[]) => void
  tokenComponent: any
  onChange?: (text: string) => void
}
export const AutocompleteBase = <T extends {id: number}>(props: AutocompleteBaseProps<T>) => {
  const {tokens, setTokens, allTokens, onChange, tokenComponent} = props
  const selectedTokenIds = tokens.map(token => token.id)
  const [selectedItemIds, setSelectedItemIds] = React.useState(selectedTokenIds)
  const onTokenRemove = (tokenId: number) => {
    setTokens(tokens.filter(token => token.id !== tokenId))
    setSelectedItemIds(selectedItemIds.filter(id => id !== tokenId))
  }
  const onSelectedChange = (newlySelectedItems: T[]) => {
    if (!Array.isArray(newlySelectedItems)) {
      return
    }

    setSelectedItemIds(newlySelectedItems.map(item => item.id))

    if (newlySelectedItems.length < selectedItemIds.length) {
      const newlySelectedItemIds = newlySelectedItems.map(({id}) => id)
      const removedItemIds = selectedTokenIds.filter(id => !newlySelectedItemIds.includes(id))

      for (const removedItemId of removedItemIds) {
        onTokenRemove(removedItemId)
      }
      return
    }

    onChange && onChange('')
    setTokens(newlySelectedItems)
  }

  return (
    <Autocomplete>
      <Autocomplete.Input
        sx={{width: '100%'}}
        as={TextInputWithTokens}
        tokens={tokens}
        tokenComponent={tokenComponent}
        onTokenRemove={onTokenRemove}
        id="autocompleteInput-issueLabels"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange && onChange(e.target.value)
        }}
      />
      <Autocomplete.Overlay>
        <Autocomplete.Menu
          items={allTokens.slice(0, 10)}
          selectedItemIds={selectedItemIds}
          onSelectedChange={onSelectedChange as any}
          selectionVariant="multiple"
          aria-labelledby="autocompleteLabel-issueLabels"
        />
      </Autocomplete.Overlay>
    </Autocomplete>
  )
}
