import {useQuery} from 'react-query'
import {headers} from '../auth'

export async function queryFunction<T>(url: string) {
  const res = await fetch(url, {
    headers: headers()
  })
  if (!res.ok) throw new Error(res.statusText)
  return (await res.json()) as T
}

export function useGitHubQuery<T>(url: string) {
  return useQuery<T, Error>(url, () => queryFunction<T>(url))
}
